/**
 * Created by PanJiaChen on 16/11/18.
 */
import axios from "axios"
import Qs from "qs"
import { Message } from "element-ui"
import store from "@/store"
import { exportMethodAll } from "@/api/export.js"
// export let envUrl = " http://192.168.43.193:9528"
// export let envUrl = "http://192.168.10.18:9528" // 自己本地的IP地址
// export let dataEnvUrl = "http://192.168.10.18:9530"
export let envUrl = "http://192.168.10.15:9528" // 自己本地的IP地址
export let dataEnvUrl = "http://192.168.10.45:9530"
    // export const envUrl = "http://39.105.232.233:8081/#" //测试
    // typeof modeStaging != "undefined" &&
if (process.env.NODE_ENV == "staging") {
    // envUrl = "http://47.92.86.229:8082";
    // envUrl = modeStaging
    envUrl = "https://pagehome.test.tg-cloud.com"
}
if (typeof modeStaging == "undefined" && process.env.NODE_ENV == "production") {
    envUrl = "https://www.tg-cloud.com"
}
if (process.env.NODE_ENV == "production") {
    envUrl = "https://www.tg-cloud.com"
    dataEnvUrl = "https://log.tg-cloud.com"
}

console.log(envUrl)
console.log(dataEnvUrl)
    /**
     * Parse the time to string
     * @param {(Object|string|number)} time
     * @param {string} cFormat
     * @returns {string | null}
     */

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null
    }
    const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}"
    let date
    if (typeof time === "object") {
        date = time
    } else {
        if (typeof time === "string" && /^[0-9]+$/.test(time)) {
            time = parseInt(time)
        }
        if (typeof time === "number" && time.toString().length === 10) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
        if (key === "a") {
            return ["日", "一", "二", "三", "四", "五", "六"][value]
        }
        return value.toString().padStart(2, "0")
    })
    return time_str
}
/**
 * 解析地址栏参数
 */
export function get_url(url) {
    let str = url;
    let num = str.indexOf("?");
    const param = {};
    str = str.substr(num + 1);
    const arr = str.split("&");
    for (let i = 0; i < arr.length; i++) {
        num = arr[i].indexOf("=");
        if (num > 0) {
            const name = arr[i].substring(0, num);
            const value = arr[i].substr(num + 1);
            param[name] = decodeURI(value);
        }
    }
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
    if (("" + time).length === 10) {
        time = parseInt(time) * 1000;
    } else {
        time = +time;
    }
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return "刚刚";
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + "分钟前";
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + "小时前";
    } else if (diff < 3600 * 24 * 2) {
        return "1天前";
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return (
            d.getMonth() +
            1 +
            "月" +
            d.getDate() +
            "日" +
            d.getHours() +
            "时" +
            d.getMinutes() +
            "分"
        );
    }
}
export function formatTime2(time, option) {
    if (("" + time).length === 10) {
        time = parseInt(time) * 1000;
    } else {
        time = +time;
    }
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return "刚刚";
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + "分钟前";
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + "小时前";
    } else if (diff < 3600 * 24 * 2) {
        return "1天前";
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return (
            d.getMonth() +
            1 +
            "月" +
            d.getDate() +
            "日" +
            d.getHours() +
            "时" +
            d.getMinutes() +
            "分"
        );
    }
}
export function goBefore() {
    var before = time - oneDay; // 计算前一天的毫秒数
    date.setTime(before);
    app.innerHTML = myGetDate(date);
}
/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
    const search = url.split("?")[1];
    if (!search) {
        return {};
    }
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
        '"}'
    );
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result;

    const later = function() {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp;

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return function(...args) {
        context = this;
        timestamp = +new Date();
        const callNow = immediate && !timeout;
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}

/**
 * 格林威治时间转换
 */
export default function getTime(time) {
    var d = new Date(time);
    var year = d.getFullYear();
    var month =
        d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : "" + (d.getMonth() + 1);
    var day = d.getDate() < 10 ? "0" + d.getDate() : "" + d.getDate();
    var hour = d.getHours() < 10 ? "0" + d.getHours() : "" + d.getHours();
    var minutes =
        d.getMinutes() < 10 ? "0" + d.getMinutes() : "" + d.getMinutes();
    var seconds =
        d.getSeconds() < 10 ? "0" + d.getSeconds() : "" + d.getSeconds();
    return (
        year + "-" + month + "-" + day + " " + hour + ":" + minutes + ":" + seconds
    );
}

// /**
//  *判断 数据中是否存在属性值 返回对象
//  * @param {*data数据，属性值} data
//  */
export function findPropertyObj(tree, current) {
    var isGet = false;
    var retNode = null;

    function deepSearch(tree, current) {
        for (var i = 0; i < tree.length; i++) {
            if (tree[i].children && tree[i].children.length > 0) {
                deepSearch(tree[i].children, current);
            }
            if (current === tree[i].menuUrl || isGet) {
                isGet || (retNode = tree[i]);
                isGet = true;
                break;
            }
        }
    }
    deepSearch(tree, current);
    return retNode;
}

/**
 * 去掉多余的chidren
 */
export function removeEmptyChildren(node) {
    node.forEach((item) => {
        if ("children" in item && item.children.length === 0) {
            delete item.children;
        } else if ("children" in item && item.children.length) {
            removeEmptyChildren(item.children);
        }
        return item;
    });
    return node;
}

/**
 *
 * @param {根据key值查询数组} key
 * @param {*} treeData
 */
export function getTreeDeepArr(key, treeData) {
    const arr = []; // 在递归时操作的数组
    let returnArr = []; // 存放结果的数组
    let depth = 0; // 定义全局层级
    // 定义递归函数
    function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
            depth = depthN; // 将执行的层级赋值 到 全局层级
            arr[depthN] = childrenData[j].label;
            if (childrenData[j].label == key) {
                // returnArr = arr; // 原写法不行, 因 此赋值存在指针关系
                returnArr = arr.slice(0, depthN + 1); // 将目前匹配的数组，截断并保存到结果数组，
                break;
            } else {
                if (childrenData[j].children) {
                    depth++;
                    childrenEach(childrenData[j].children, depth);
                }
            }
        }
        return returnArr;
    }
    return childrenEach(treeData, depth);
}

/**
 *
 * @param {时间转化为毫秒} key
 * @param {*}
 */
export function getMiao(time) {
    time = time.replace(/-/g, "/");
    var date = new Date(time);
    return date;
}
/**
 *
 * @param {获取当前时间年月日时分秒} key
 * @param {*}
 */

Date.prototype.Format = function(fmt) {
    var o = {
        "M+": this.getMonth() + 1, // 月份
        "d+": this.getDate(), // 日
        "h+": this.getHours(), // 小时
        "m+": this.getMinutes(), // 分
        "s+": this.getSeconds(), // 秒
        "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds(), // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
            );
        }
    }
    return fmt;
};

// 获取当前时间
// var now = new Date();
// //当前时间
// formatDateTime(now);//2019-12-24 14:58:30
// //六小时前时间
// formatDateTime(new Date().setHours(now.getHours()-6));//2019-12-24 09:10:45
// //一天前时间
// formatDateTime(new Date().setDate(now.getDate()-1));//2019-12-23 14:58:45
// //一周前时间
// formatDateTime(new Date().setDate(now.getDate()-7));//2019-12-23 14:58:45
// //一月前时间
// formatDateTime(new Date().setMonth(now.getMonth()-1));//2019-11-24 14:58:45

// 时间戳格式转换
export function formatDateTime(Time) {
    var date = new Date(Time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
    return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
}

// 获取当前年月日
export function getCurrentDate(Time, isCurrent) {
    var date = new Date(Time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    if (!isCurrent) {
        var d = date.getDate() - 1;
        d = d < 10 ? "0" + d : d;
    }
    return y + "-" + m + "-" + d;
}
// 获取当前年月
export function getCurrentMonth(Time) {
    var date = new Date(Time);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    return y + "-" + m;
}
/**
 *
 * @param {*} date 传入时间
 * @param {*} day  加或减的天数
 * @returns
 */
export function getNextDate(date, day = 0) {
    var dd = new Date(date);
    dd.setDate(dd.getDate() + day);
    var y = dd.getFullYear();
    var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
    var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
    return y + "-" + m + "-" + d;
}
/**
 * 通过哈希判断数组中是否有重复元素
 */
export function isRepeat(arr) {
    var hash = {};
    for (var i in arr) {
        if (hash[arr[i]]) {
            return true;
        }
        hash[arr[i]] = true;
    }
    return false;
}
/**
 * 数组去重
 */
export function unique(arr, key) {
    const res = new Map();
    return arr.filter((arr) => !res.has(arr[key]) && res.set(arr[key], 1));
}
/**
 * ios时间转换格式
 */
export function formatDate(date) {
    var date = new Date(date);
    var YY = date.getFullYear() + "/";
    var MM =
        (date.getMonth() + 1 < 10 ?
            "0" + (date.getMonth() + 1) :
            date.getMonth() + 1) + "/";
    var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
    var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return YY + MM + DD + " " + hh + mm + ss;
}
/**
 * 根据key查找对像
 * @param {data} data 数组
 * @param {*current} current  key
 */

export function getCurrentPart(data, current) {
    var result = null;
    if (!data) {
        return;
    }
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (item.id === current) {
            result = item;
            return result;
        }
        if (item.children && item.children.length > 0) {
            result = getCurrentPart(item.children, current);
            if (result) return result;
        }
    }
}
/**
 * 根据label查找对像
 * @param {data} data 数组
 * @param {*current} current  key
 */

export function getCurrentLabel(data, current, name = "label") {
    var result = null;
    if (!data) {
        return;
    }
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (item[name] === current) {
            result = item;
            return result;
        }
        if (item.children && item.children.length > 0) {
            result = getCurrentLabel(item.children, current, name);
            if (result) return result;
        }
    }
}
/**
 * 数据导出
 * @param {params} params 参数
 * @param {*url} current  地址
 * @param {*method} method 方式
 */
export function exportMethod(data) {
    // console.log(data.params)
    const { method, url, fileName } = data;
    if (data.head == "formData") {
        data.params = Qs.stringify(data.params);
    }
    var result = 0;
    return new Promise((resolve, reject) => {
        // axios({
        //   method: method,
        //   url: `${url}`,

        //   responseType: "blob",
        //   data: data.params,
        //   config: {
        //     headers: {
        //       token: store.getters.token,
        //       hhh: 66666,
        //     },
        //   },
        // })
        exportMethodAll(method, url, data.params, data.data, data.head)
            .then((res) => {
                data.load = false;
                const link = document.createElement("a");
                const blob = new Blob([res.data], {
                    type: "application/vnd.ms-excel;charset=UTF-8",
                });
                link.style.display = "none";
                link.href = URL.createObjectURL(blob);
                // link.download = res.headers['content-disposition'] //下载后文件名
                // console.log(new String(str.getBytes("ISO8859-1"), "gb2312"));
                link.download = fileName; // 下载的文件名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                result = 0;
                resolve(result);
            })
            .catch((error) => {
                result = 1;
                reject(error);
            });
    });
}
/** 根据id查找对象 */
export function findItemById(id, list) {
    let res = list.find((item) => item.id == id);
    if (res) {
        return res;
    } else {
        for (let i = 0; i < list.length; i++) {
            if (list[i].children instanceof Array && list[i].children.length > 0) {
                res = findItemById(id, list[i].children);
                if (res) {
                    return res;
                }
            }
        }
        return null;
    }
}

/**
 * @returns {string}
 */
export function randomStr(len = 32) {
    const $chars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    const maxPos = $chars.length;
    let str = "";
    for (let i = 0; i < len; i++) {
        str += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return str;
}
/**
 * 上传文件
 */
export function uploadAvatar(res) {
    var resFile = "";
    if (res.code == 200) {
        Message({
            message: "上传成功",
            type: "success",
        });
        resFile = res.data;
        return resFile;
    } else if (res.code == 20002) {
        setTimeout(() => {
            window.top.location.href = envUrl + "/login";
        }, 500);
    } else {
        Message({
            message: "请重新上传",
            type: "warning",
        });
        return "";
    }
}

// 换算方法

const nowClientWidth = document.documentElement.clientWidth
export function nowSize(val, initWidth = 1920) {
    // console.log(nowClientWidth)
    // console.log(val * (nowClientWidth / initWidth))
    return val * (nowClientWidth / initWidth);
}
// 报修统计
export function nowSize2(val, ele) {
    const eleW = document.getElementById(ele).clientWidth;
    console.log(eleW);
    return eleW / val;
}
// 自适应宽高
export function myChartContainer(myChart) {
    myChart.style.width = window.innerWidth / 4.5 + "px";
    myChart.style.height = window.innerHeight / 3.5 + "px";
    return myChart;
}
// 分割数组
export function sliceArray(array, size) {
    var result = [];
    for (var x = 0; x < Math.ceil(array.length / size); x++) {
        var start = x * size;
        var end = start + size;
        result.push(array.slice(start, end));
    }
    return result;
}
// 获取当前月第一天
export function getCurrentMonthFirst() {
    var date = new Date();
    date.setDate(1);
    var month = parseInt(date.getMonth() + 1);
    var day = date.getDate();
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    return date.getFullYear() + "-" + month + "-" + day;
}
export function formatDecimal(num, decimal = 2) {
    let result = "";
    if (num == null || num == "null" || num == "undefined" || num == undefined) {
        return result;
    } else {
        num = num.toString();
        const index = num.indexOf(".");
        if (index !== -1) {
            num = num.substring(0, decimal + index + 1);
        } else {
            num = num.substring(0);
        }
        return (result = parseFloat(num).toFixed(decimal));
    }
}
// 获取当天日期
export function getDateTime(type) {
    // 获取当前日期
    // let timestamp = Date.parse(new Date());
    // let date = new Date(timestamp);
    // if (type == 'tomorrow') { // 明天
    //     date.setDate(date.getDate() + 1);
    // } else if (type == 'today') { // 今天
    //     date.setDate(date.getDate());
    // }
    // //获取年份

    // //获取月份
    // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    // //获取当日日期
    // var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    // let todayDate = Y + '-' + M + '-' + D;
    // return todayDate
    // if (("" + time).length === 10) {
    //   time = parseInt(time) * 1000;
    // } else {
    //   time = +time;
    // }
    var date = new Date();
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? "0" + m : m;
    var d = date.getDate();
    d = d < 10 ? "0" + d : d;
    var h = date.getHours();
    h = h < 10 ? "0" + h : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
    return y + m + d + h + minute + second;
}