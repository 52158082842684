import axios from "axios"
import { Message } from "element-ui"
import VueRouter from "@/router/index"
import store from "@/store"
import { envUrl } from "@/utils"

const service = axios.create({
    // baseURL:  ? VUE_APP_BASE_API_EQUIRE : process.env.VUE_APP_BASE_API ,

    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 40000000000
})
service.interceptors.request.use(
    (config) => {
        if (store.getters.token) {
            config.headers["token"] = store.getters.token
        }
        return config
    },
    (error) => {
        console.log(error)
    }
)

service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code != 200) {
            if (res.code == 20002) {
                setTimeout(() => {
                    window.location.href = envUrl + "/login?redirect=" + VueRouter.history.current.name

                    window.parent.postMessage({ type: 'tokenInvalid' }, '*')
                        //   window.location.href =
                        // envUrl + "/login?redirect=" + sessionStorage.getItem("pathName");
                        //   window.name = "";
                }, 500)
            } else {
                // Message({
                //     message: res.msg || "Error",
                //     type: "error"
                // });
                // Promise.reject(new Error(res.msg || "Error"));
                return res
            }
            // Message({
            //   message: res.msg || "请稍后重试",
            //   type: "waring",
            // });
        } else {
            return res
        }
    },
    (error) => {
        console.error(error.response)
        if (error.response.status == 401) {
            Message({
                message: error.response.data.msg || "请稍后重试",
                type: "waring"
            })
            window.location.href =
                envUrl + "/login?redirect=" + VueRouter.history.current.name
        }
        if (error.response.status == 504) {
            Message({
                message: error.response.data.msg || "查询超时",
                type: "waring"
            })
        }
        return error
    }
)
export default service

// window.addEventListener("beforeunload", () => {
//     window.top.location.href = envUrl + "/login?redirect=" + VueRouter.history.current.name
// });